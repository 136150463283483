import { cx } from '@emotion/css';
import { Trans } from '@sortlist-frontend/translation/ssr';
import { FC } from 'react';

import { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import { SectionsDetails } from '_features/agency/profile/components';
import * as S from '_features/agency/profile/sections/services/services.styles';
import { Section } from '_features/agency/profile/utils';

import { ServiceWork } from './service-work.component';

type Props = {
  agencyName: string;
  agencySlug: string;
  agencyLogoUrl: string;
  serviceName: string;
  worksCount: GetAgencyServices['services'][0]['works']['count'];
  works: GetAgencyServices['services'][0]['works']['data'];
  className?: string;
  handleActivePanel: (value: Section | undefined) => void;
};

export const ServiceWorks: FC<Props> = (props) => {
  const { agencyName, agencySlug, className, agencyLogoUrl, serviceName, works, worksCount, handleActivePanel } = {
    ...props,
  };
  const maxNumberOfCardsDisplayed = 4;

  const workSlugs = works?.map((work) => work.slug);

  return (
    <section className={cx(className, 'p-16 layout-column')}>
      <header className="p-8">
        <Trans i18nKey={'agency:profile.services.worksTitle'} count={worksCount}>
          <b>Works</b> in {{ serviceName: serviceName }} ({{ count: worksCount }})
        </Trans>
      </header>
      <div className="layout-row p-8">
        <div className="flex layout-row layout-wrap">
          {works.slice(0, maxNumberOfCardsDisplayed).map((work, index) => (
            <ServiceWork
              key={`${work.name}-${index}`}
              agencyName={agencyName}
              agencySlug={agencySlug}
              agencyLogoUrl={agencyLogoUrl}
              index={index}
              work={work}
              serviceName={serviceName}
              workSlugs={workSlugs}
            />
          ))}
          {worksCount > maxNumberOfCardsDisplayed && (
            <div className="pr-8 pb-8 flex-xs-100 flex-sm-33 flex-gt-sm-20">
              <S.ItemExpand
                size="lg"
                buttonStyle="secondary"
                className="layout-fill bg-secondary-200 text-secondary-500"
                label={`+${worksCount - maxNumberOfCardsDisplayed}`}
                onClick={() => handleActivePanel(SectionsDetails?.find((section) => section.id === 'portfolio'))}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
