import { cx } from '@emotion/css';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC, useState, Fragment } from 'react';

import { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import { DirectBriefingButton } from '_components/Briefing/versions/DirectBriefing/button';
import { ClampLines } from '_components/common/ClampLines';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';

type Props = {
  agencySlug: string;
  description: string;
  service: GetAgencyServices['services'][0];
  skills: GetAgencyServices['services'][0]['skills']['data'];
  className?: string;
  clampLines?: number;
};

const defaultProps = {
  clampLines: 7,
};

export const ServiceAbout: FC<Props> = (props) => {
  const { agencySlug, className, description, skills, service, clampLines } = { ...defaultProps, ...props };
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const [toggledSkills, setToggledSkills] = useState(false);
  const maxNumberOfSkillsDisplayed = 10;

  return (
    <Fragment>
      <section className={cx(className, 'p-16')}>
        <header className="p-8">
          <span className="bold">{t('agency:profile.services.descriptionTitle')}</span>
        </header>
        <div className="layout-row">
          <div className="p-8 flex">
            {description.length > 0 ? (
              <ClampLines text={description} lines={clampLines} />
            ) : (
              <p className="text-secondary-700 italic">{t('agency:profile.services.noDescription')}</p>
            )}
          </div>
          <div className="p-8 hide show-gt-sm flex-20 layout-column layout-align-start-stretch">
            <DirectBriefingButton
              cta="service-about"
              agencySlug={agencySlug as string}
              size={'sm'}
              truncate={false}
              buttonStyle="primary"
              buttonVariant="raised"
              label={t('agency:profile.services.quoteCtaBtn')}
            />
          </div>
        </div>
      </section>
      {skills.length > 0 && (
        <section className="p-16 layout-column">
          <header className="p-8">
            <span>
              <Trans i18nKey={'agency:profile.services.skillsTitle'} count={skills.length}>
                <b>Skills</b> in {{ serviceName: service.name }} ({{ count: skills.length }})
              </Trans>
            </span>
          </header>
          <div className="p-8 layout-row layout-wrap">
            {(skills.length > maxNumberOfSkillsDisplayed && toggledSkills
              ? skills
              : skills.slice(0, maxNumberOfSkillsDisplayed)
            ).map((skill) => (
              <span
                key={`${skill.slug}`}
                className="mr-8 mb-8 bg-secondary-300 rounded-xs px-8 py-4 small text-secondary-900">
                {skill.name}
              </span>
            ))}
            {skills.length > maxNumberOfSkillsDisplayed && (
              //  eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                tabIndex={0}
                role={'button'}
                className="cursor-pointer mb-8 bg-secondary-300 rounded-xs px-8 py-4 small text-secondary-900"
                onClick={() => setToggledSkills(!toggledSkills)}
                onKeyDown={() => setToggledSkills(!toggledSkills)}>
                {toggledSkills ? t('common:clampLines.seeLess') : `+${skills.length - maxNumberOfSkillsDisplayed}`}
              </a>
            )}
          </div>
        </section>
      )}
    </Fragment>
  );
};
