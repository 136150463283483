import { cx } from '@emotion/css';
import { ReactNode } from 'react';

type Props = {
  title?: ReactNode | string;
  subtitle?: ReactNode | string;
  className?: string;
  children?: ReactNode;
  childrenHeader?: ReactNode;
  id?: string;
};

export const SectionBox: React.FC<Props> = (props) => {
  const { title, subtitle, className, children, id, childrenHeader } = props;

  return (
    <div className={cx(className)} id={id}>
      {title && subtitle ? (
        <div className="layout-row layout-xs-column layout-align-space-between-center layout-align-xs-start-start px-32 px-gt-sm-64 pb-32 pb-gt-sm-64">
          <div className="layout-column">
            <h2 className="bold">{title}</h2>
            <h3 className="p mt-8">{subtitle}</h3>
          </div>
          {childrenHeader && <div>{childrenHeader}</div>}
        </div>
      ) : null}
      {!!children ? children : null}
    </div>
  );
};
