import { cx } from '@emotion/css';
import { Divider, Tooltip, useMediaQuery } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { DynamicTranslation, useTranslation } from '@sortlist-frontend/translation/ssr';
import Image from 'next/image';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';
import { onImageError } from '_core/media/get-media.utils';
import { useGetAgencyProfile } from '_core/repos/agency-profile.repo';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { SectionBox } from '_features/agency/profile/components/section-box';

import * as S from './hero.styles';
import { getCoverUrl } from './utils';

type Props = {
  sections?: {
    id: string;
    label: string;
    icon: ReactNode;
  }[];
  children?: never;
};

export const HeroPanel: FC<Props> = (props) => {
  const { sections } = props;

  const { locale, pageData } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data } = useGetAgencyProfile(agencySlug, locale as string);

  const agencyName = data?.name;
  const cover = data?.medias.cover;
  const coverUrl = useMemo(() => getCoverUrl(cover), [cover?.url]);
  const agencyLogoUrl = data?.medias.logo?.url;

  const [scroll, setScroll] = useState<number>(0);
  const [isStickyBar, setStickyBar] = useState<boolean>(false);

  const { media } = useMediaQuery();
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const anchorBar = useRef<HTMLDivElement>(null);

  const handleStickyAnchorbar = () => {
    if (anchorBar && anchorBar.current) {
      setScroll(anchorBar.current.getBoundingClientRect().top);
    }
    if (!media.xs && anchorBar && anchorBar.current && anchorBar.current.getBoundingClientRect().top <= 80) {
      setStickyBar(true);
    } else setStickyBar(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleStickyAnchorbar, true);
    return () => {
      window.removeEventListener('scroll', handleStickyAnchorbar, true);
    };
  }, [scroll]);

  return (
    <SectionBox className={'display-inline relative'}>
      <S.HeroSectionHeader
        className={
          'overflow-hidden rounded-top-left-xl border-bottom border-secondary-200 rounded-top-right-xl width-100 bg-secondary-100 relative'
        }>
        <Image
          src={coverUrl}
          sizes={'(max-width: 600px) 600px, 1080px'}
          alt={`${agencyName} cover`}
          onError={onImageError}
          fill
        />
      </S.HeroSectionHeader>
      <S.HeroSectionAnchorBar
        ref={anchorBar}
        className={`${isStickyBar && 'shadow-2 bg-neutral-100 sticky'} layout-row layout-align-center-center px-0`}>
        <div className="px-gt-sm-64 layout-gt-sm-row flex layout-column layout-align-gt-sm-center-center layout-align-center-stretch relative">
          {!(isStickyBar && media.gtSm) ? (
            <S.HeroSectionLogo
              width={112}
              height={112}
              src={ImageSourceHelper.fromUrl(agencyLogoUrl as string, {
                width: 150,
                quality: 95,
                format: 'gif',
              })}
              alt={`${agencyName} logo`}
              className="bg-neutral-100 rounded-md shadow-2"
            />
          ) : null}
          <div className="layout-column flex hide-xs">
            <div className="layout-row overflow-y-auto">
              {sections &&
                sections.map((section, index) => (
                  <div
                    style={{ maxWidth: `${media.gtSm ? 100 / sections.length : 100}%` }}
                    className="layout-row flex-noshrink"
                    key={section.id}>
                    <Tooltip
                      title={
                        t(`agency:profile.${section.id}.title` as unknown as DynamicTranslation<typeof t>) as string
                      }>
                      <a
                        className={cx(
                          { 'border-right-secondary-500': index + 1 != sections.length },
                          'small cursor-pointer flex layout-row layout-align-center-center btn btn-md btn-secondary btn-default',
                        )}
                        href={`#${section.id}`}>
                        <div className="mr-8 layout">{section.icon}</div>
                        <span className="text-truncate">
                          {t(`agency:profile.${section.id}.title` as unknown as DynamicTranslation<typeof t>)}
                        </span>
                      </a>
                    </Tooltip>
                    <Divider className={cx({ hide: index + 1 === sections.length }, 'width-100 my-8')} />
                  </div>
                ))}
            </div>
            {!isStickyBar && <Divider />}
          </div>
        </div>
      </S.HeroSectionAnchorBar>
    </SectionBox>
  );
};
