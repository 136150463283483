import { AnalyticsProvider, TrackingProvider } from '@sortlist-frontend/tracking';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { assertNonEmptyString, HttpBadRequest, isPlainObject } from '@sortlist-frontend/utils';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import Head from 'next/head';
import { Fragment, useRef } from 'react';

import { NotFoundException } from '_backend/core/cqs-query/exception';
import { GetAgencyProfile } from '_backend/queries/get-agency-profile/get-agency-profile.types';
import { getAgencyAlternates } from '_components/AlternateLinks/utils';
import { PageHead } from '_components/base/head';
import { Public404Page } from '_components/error/public-404-page';
import { LayoutPagesRouter } from '_components/layout/LayoutPagesRouter';
import { cacheConfig } from '_config/cache.config';
import { extractDomainRelevantInfoFromHeaders } from '_config/domain.config';
import { AppProviders } from '_core/app-providers';
import { PublicAppContextProvider } from '_core/context/public-app-context';
import { isSerializedError, SerializedError, serializeError } from '_core/error/error-serializer';
import { cacheKey, useGetAgencyProfile, useGetAgencyServices } from '_core/repos/agency-profile.repo';
import * as publicApi from '_core/repos/public-api.repo';
import { baseSsrPrefetchQueries } from '_core/utils/public-api';
import { getServerSideTranslations } from '_core/utils/ssr';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { AgencyProfilePage } from '_features/agency/profile/agency-profile.page';
import { agencyProfileRepo } from '_features/agency/profile/ssr/get-agency-profile-data.ssr';

const { getPublicApiRequestHomeLink, publicApiRepo, PublicApiRepo, cacheKey: cacheKeyPublicApi } = publicApi;
const DOMAIN_CONFIG_ERROR_STATUSES = [400, 404, 410];

type Props = {
  locale?: string;
  canonical: string;
  origin?: string;
  agencySlug: string;
  error: SerializedError | null;
  disableCache: boolean;
  top: string[] | string | null;
  publicApiNavBarUrl?: string;
  dehydratedState?: DehydratedState;
};

export default function PageRoute(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return (
    <AppProviders dehydratedState={props.dehydratedState}>
      <Route {...props} />;
    </AppProviders>
  );
}

const Route = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const locale = props?.locale || 'en';
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const scrollableCtn = useRef<HTMLDivElement>(null);
  const { data: agencyMainData } = useGetAgencyProfile(props?.agencySlug, locale);
  const { data: services } = useGetAgencyServices(props?.agencySlug, locale);

  if (!props) return;

  const { agencySlug, error, canonical, origin, disableCache, top, publicApiNavBarUrl = '' } = props;

  if (error && isSerializedError(error)) {
    const { status } = error;
    if (DOMAIN_CONFIG_ERROR_STATUSES.includes(status as number))
      return <Public404Page message={t('common:pageErrors.notFound.agencyNotFound')} locale={locale} />;
  }

  PublicApiRepo.publicApiNavBarUrl = publicApiNavBarUrl;

  const expertiseId = services?.services[0]?.expertise_id;
  return (
    <Fragment>
      <PageHead canonical={canonical} origin={origin} />
      {/* For some reason if I add the alternates in the PageHead component only 7 finish in the dom, really weird */}
      <Head>
        {getAgencyAlternates(agencySlug, origin as string, locale).map(({ key, href, hreflang }) => {
          return <link key={key} rel="alternate" href={href} hrefLang={hreflang} />;
        }) ?? null}
      </Head>
      <PublicAppContextProvider
        canonical={canonical}
        origin={origin || ''}
        locale={locale}
        pageData={{ agencySlug }}
        briefingOptions={{
          page: 'agency-profile',
          address: agencyMainData?.country,
          placeId: agencyMainData?.country_place_id,
          expertise: expertiseId as number,
          iso31661: agencyMainData?.iso31661,
        }}>
        <TrackingProvider
          app="appPublic"
          category={'agency-profile'}
          name={'public'}
          hideIntercom={true}
          properties={{
            agencySlug: agencyMainData?.slug,
            brandingModule: agencyMainData?.branding_plan,
          }}>
          <AnalyticsProvider
            segmentOptions={{
              excludedIntegrations: [
                'Bing Ads',
                'Facebook Pixel',
                'Google AdWords Remarketing Lists (Customer Match)',
                'Hotjar',
                'HubSpot',
                'Intercom',
              ],
            }}>
            <LayoutPagesRouter scrollableCtn={scrollableCtn}>
              <AgencyProfilePage disableCache={disableCache} scrollableCtn={scrollableCtn} top={top} />
            </LayoutPagesRouter>
          </AnalyticsProvider>
        </TrackingProvider>
      </PublicAppContextProvider>
    </Fragment>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  const { disableCache: disableCacheParam, top } = context.query;
  const { i18nNamespaces } = agencyProfileConfig;
  const agencySlug = context.params?.slug as string;
  let error: SerializedError | null = null;
  const disableCache = disableCacheParam === 'true';

  let domainRelevantInfo;
  try {
    domainRelevantInfo = extractDomainRelevantInfoFromHeaders(context.req);
  } catch (e) {
    // @TODO: use a more elegant way when upgrade to NextJS 10 https://nextjs.org/blog/next-10#redirect-and-notfound-support-for-getstaticprops--getserversideprops
    context.res.statusCode = (e as any).status;
    error = serializeError(e as Error);

    return {
      props: {
        error,
        disableCache,
        canonical: '',
        agencySlug,
        top: (top as string) || null,
        ...(await getServerSideTranslations('en', i18nNamespaces)),
      },
    };
  }

  const { canonical, origin, locale } = domainRelevantInfo;

  assertNonEmptyString(origin, new HttpBadRequest({ message: 'Missing [origin] parameter' }));
  assertNonEmptyString(agencySlug, new HttpBadRequest({ message: 'Missing [agencySlug] parameter' }));

  const queryClient = new QueryClient();
  let agencyProfile: GetAgencyProfile | null = null;

  let publicApiNavBarUrl = '';

  try {
    agencyProfile = await agencyProfileRepo.getAgencyProfile({ agencySlug, locale, disableCache });
    const response = await baseSsrPrefetchQueries(origin, locale, context.resolvedUrl, queryClient);
    publicApiNavBarUrl = response.publicApiNavBarUrl;

    await Promise.allSettled([
      queryClient.prefetchQuery({
        queryKey: [cacheKey.agencyProfile(agencySlug, locale)],
        queryFn: () => agencyProfile,
      }),
      queryClient.prefetchQuery({
        queryKey: [cacheKey.agencyAwards(agencySlug, locale)],
        queryFn: () => agencyProfileRepo.getAgencyAwards({ agencySlug, locale, disableCache }),
      }),
      queryClient.prefetchQuery({
        queryKey: [cacheKey.agencyReviews(agencySlug, locale)],
        queryFn: () => agencyProfileRepo.getAgencyReviews({ agencySlug, locale, disableCache }),
      }),
      queryClient.prefetchQuery({
        queryKey: [cacheKey.agencySeoDetails(agencySlug, locale)],
        queryFn: () => agencyProfileRepo.getAgencySeoDetails({ agencySlug, locale, disableCache }),
      }),
      queryClient.prefetchQuery({
        queryKey: [cacheKey.agencyPortfolio(agencySlug, locale)],
        queryFn: () => agencyProfileRepo.getAgencyPortfolio({ agencySlug, locale, disableCache }),
      }),
      queryClient.prefetchQuery({
        queryKey: [cacheKey.agencyServices(agencySlug, locale)],
        queryFn: () => agencyProfileRepo.getAgencyServices({ agencySlug, locale, disableCache }),
      }),
      queryClient.prefetchQuery({
        queryKey: [cacheKey.agencyTeam(agencySlug, locale)],
        queryFn: () => agencyProfileRepo.getAgencyTeam({ agencySlug, locale, disableCache }),
      }),
      queryClient.prefetchQuery({
        queryKey: [cacheKey.agencyOffices(agencySlug, locale)],
        queryFn: () => agencyProfileRepo.getAgencyOffices({ agencySlug, locale, disableCache }),
      }),
    ]);
  } catch (e) {
    // Limitation: Typescript exceptions are not typed, so we rely on what is supposed to happen
    if (!(e instanceof NotFoundException)) {
      throw e;
    }
    // @todo: use a more elegant way when upgrade to NextJS 10 https://nextjs.org/blog/next-10#redirect-and-notfound-support-for-getstaticprops--getserversideprops
    context.res.statusCode = 404;

    error = serializeError(e);
  }

  const cacheOptions = cacheConfig.pages['agencyProfile'];

  if (!context.res.headersSent && agencyProfile) {
    const { uuid } = agencyProfile;
    context.res.setHeader('Surrogate-Key', `agency-${uuid}`);

    // Enable edge caching
    if (isPlainObject(cacheOptions) && !disableCache) {
      try {
        context.res.setHeader('Cache-Control', cacheOptions.httpCacheControl);
        context.res.setHeader('Vary', cacheOptions?.httpVary?.join(',') || '');
      } catch (e) {
        // too dangerous to not discard error here
        // in case of res.end() was called and headerSent is not trustable.
        // @todo find a better way to handle redirects before with Nextjs and
        //       required props
      }
    }
  }

  return {
    props: {
      locale,
      canonical,
      origin,
      agencySlug,
      publicApiNavBarUrl,
      dehydratedState: dehydrate(queryClient),
      error,
      disableCache,
      top: top || null,
      ...(await getServerSideTranslations(locale, i18nNamespaces)),
    },
  };
};
