import { cx } from '@emotion/css';
import { Libraries, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isBrowser } from '@sortlist-frontend/utils';
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { GetAgencyOffices, Office } from '_backend/queries/get-agency-offices/get-agency-offices.types';
import { LIBRARIES } from '_core/google-maps/constants';
import { useTracker } from '_core/hooks/use-tracker';
import { findFirstOfficeIndex } from '_features/agency/profile/sections/contact/utils';

import { agencyProfileConfig } from '../../agency-profile.config';
import * as S from './contact.styles';

type Props = {
  children?: never;
  agencyName: string;
  agencySlug: string;
  className?: string;
  selectedOffice: Office | null;
  offices: GetAgencyOffices | undefined;
};

const zoomSizes = {
  1: 200,
  2: 90,
  3: 40,
  4: 10,
  5: 8,
  6: 7,
  7: 6,
  8: 5,
  9: 4,
  10: 3,
  11: 2,
  12: 1,
};

export const GoogleMapCtn: FC<Props> = (props) => {
  const { offices: data, agencyName, agencySlug, selectedOffice, className } = props;
  const { trackUntyped } = useTracker();
  const GoogleMapRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACE_API_KEY as string,
    libraries: LIBRARIES as Libraries,
  });
  const isLoadedMaps = isBrowser() && isLoaded;

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zoom, setZoom] = useState(11);
  const [center, setCenter] = useState({
    lng: 0,
    lat: 0,
  });

  useEffect(() => {
    if (isLoadedMaps && selectedOffice !== null) {
      const index = findFirstOfficeIndex(data?.offices, selectedOffice);
      if (index !== null) {
        trackUntyped('locationInspected', { agencyName, agencySlug, address: selectedOffice.address, page: 'profile' });
        if (map) {
          setCenter({
            lat: selectedOffice.latitude,
            lng: selectedOffice.longitude,
          });
          const bounds = new window.google.maps.LatLngBounds();
          map.fitBounds(bounds);
        }
      }
    }
  }, [selectedOffice]);

  // Yes on exception to the rule, because re-rendering maps
  // is too costly so let's deep-compare the dependency array
  useDeepCompareEffect(() => {
    if (data) {
      if (data.offices.length > 0) {
        setCenter({
          lng: data.offices[0].longitude,
          lat: data.offices[0].latitude,
        });
      }
      if (data.offices.length > 1) {
        const longitudes = data.offices.map((office) => office.longitude);
        const latitudes = data.offices.map((office) => office.latitude);
        const latDiff = Math.max(...latitudes) - Math.min(...latitudes);
        const lngDiff = Math.max(...longitudes) - Math.min(...longitudes);

        Object.entries(zoomSizes).some(([key, value]) => {
          if (latDiff > value || lngDiff > value) {
            setZoom(Number(key));
            return true;
          }
          return false;
        });
      }
    }
  }, [data]);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  // Adding alt attribute to Google map img for SEO purposes.
  useEffect(() => {
    const images = GoogleMapRef?.current?.getElementsByTagName('img');
    if (images) {
      for (let i = 0; i < images?.length; i++) {
        images[i].setAttribute('alt', `Google map - ${i}`);
      }
    }
  });

  if (loadError) {
    return (
      <div className="flex-gt-sm bg-secondary-300 layout-row layout-align-center-center">
        {t('agency:profile.contact.mapErrorLoaded')}
      </div>
    );
  }

  return (
    <S.MapContainer ref={GoogleMapRef} className={cx(className, 'flex-gt-sm bg-secondary-300')}>
      {isLoadedMaps && data?.offices && (
        <S.Map mapContainerStyle={containerStyle} center={center} zoom={zoom} onLoad={onLoad} onUnmount={onUnmount}>
          <MarkerClusterer zoomOnClick={true}>
            {(clusterer) => {
              return (
                <Fragment>
                  {data.offices.map((office) => (
                    <Marker
                      key={office.position}
                      label={
                        {
                          text: office.localityName,
                          className: 'bg-primary-100 shadow-2 text-primary-700 mb-64 p-4 rounded-sm',
                          fontSize: '16px',
                        } as unknown as google.maps.MarkerLabel
                      }
                      position={{ lng: office.longitude, lat: office.latitude }}
                      clusterer={clusterer}
                    />
                  ))}
                </Fragment>
              );
            }}
          </MarkerClusterer>
        </S.Map>
      )}
    </S.MapContainer>
  );
};
