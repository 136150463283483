import { cx } from '@emotion/css';
import { ContactsTwoTone } from '@mui/icons-material';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC, Fragment } from 'react';

import { ClampLines } from '_components/common/ClampLines';
import { CtaBox } from '_components/common/CtaBox';
import { LazyImage } from '_components/image/lazy-image';
import { usePublicAppContext } from '_core/context/public-app-context';
import { getGumletImg, onImageError } from '_core/media/get-media.utils';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { SectionBox } from '_features/agency/profile/components/section-box';

import { useGetAgencyTeam } from '../../../../../core/repos/agency-profile.repo';
import * as S from './team.styles';

type Props = {
  className?: string;
  children?: never;
};

export const TeamPanel: FC<Props> = (props) => {
  const { className } = props;
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { locale, pageData } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data: team } = useGetAgencyTeam(agencySlug, locale as string);
  const { agency_name: agencyName, member_count: memberCount, cover, story } = team ?? {};

  return (
    <SectionBox
      id="team"
      title={t('agency:profile.team.title')}
      className={cx(className, 'py-gt-xs-96 py-xs-64')}
      subtitle={
        <Trans i18nKey={'agency:profile.team.subtitle'} count={memberCount}>
          <b>
            {{ count: memberCount }} members
          </b>
          in {{ name: agencyName }}'s team
        </Trans>
      }>
      <div className="px-gt-sm-48 px-32">
        {cover && (
          <div className="px-gt-sm-16 mb-32">
            <S.TeamPicture>
              <LazyImage
                strategy={'browser'}
                imgLoading={'lazy'}
                imgProps={{
                  srcSet: `${getGumletImg(cover, { preferredWidth: 800, mobile: true })} 480w,
                      ${getGumletImg(cover, { preferredWidth: 1024, mobile: false })} 800w`,
                  sizes: '(max-width: 600px) 480px, 800px',
                  src: getGumletImg(cover, { preferredWidth: 800, mobile: true }),
                  alt: `${agencyName} cover`,
                  onError: onImageError,
                  className: 'width-100 rounded-xl bg-secondary-100',
                }}
              />
            </S.TeamPicture>
          </div>
        )}
        {story && (
          <div className="layout-column px-16">
            <span className="mb-16 bold h6">{t('agency:profile.team.storyTitle')}</span>
            <ClampLines text={story} className="lh-2" />
          </div>
        )}
        <footer className="hide-xs px-32 mt-64 layout-row layout-align-center-center">
          <CtaBox
            title={t('agency:profile.team.cta.title', { name: agencyName })}
            subtitle={t('agency:profile.team.cta.subtitle')}
            btnLabel={t('agency:profile.team.cta.btnLabel')}
            icon={<ContactsTwoTone />}
            element="team-panel"
            agencySlug={agencySlug}
          />
        </footer>
      </div>
    </SectionBox>
  );
};
