import { usePromise } from '@desync/use-promise';
import { cx } from '@emotion/css';
import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import { Avatar, Button, Divider, useMediaQuery } from '@sortlist-frontend/design-system';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC, Fragment, useEffect, useState } from 'react';

import { AgencyCompetitor } from '_backend/queries/get-agency-competitors/get-agency-competitors.types';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useTracker } from '_core/hooks/use-tracker';
import { getGumletImg } from '_core/media/get-media.utils';
import { TrackingParamsCpc, useTrack } from '_core/repos/track.repo';
import { execWithTimeout } from '_core/utils/utils';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { SectionBox } from '_features/agency/profile/components/section-box';

import { fetchCompetitors } from './similar.api';
import * as S from './similar.styles';

type Props = {
  agencySlug: string;
  agencyName: string;
  locale: string;
  className?: string;
  disableCache?: boolean;
  children?: never;
};

export const SimilarPanel: FC<Props> = (props) => {
  const { agencySlug, agencyName, locale, className, disableCache } = props;
  const { trackUntyped } = useTracker();
  const { mutateAsync: internalTrack } = useTrack();
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const {
    media: { xs: isXs, sm: isSm },
  } = useMediaQuery();
  const [maxSlice, setMaxSlice] = useState(0);
  const [minSlice, setMinSlice] = useState(0);
  const { getUrl } = usePublicAppContext();

  const { data: competitors } = usePromise(fetchCompetitors, { agencySlug, locale, disableCache });

  useEffect(() => {
    if (!competitors) return;

    if (isXs && competitors.length < 3) {
      setMaxSlice(competitors.length);
    } else if (isSm === true) {
      setMaxSlice(2);
    } else {
      setMaxSlice(3);
    }
  }, [isXs, isSm, competitors]);

  const handleSeeAll = () => {
    if (competitors && competitors?.length > 0) {
      setMaxSlice(competitors?.length);
      setMinSlice(0);
    }
  };

  const handleNext = () => {
    setMinSlice(minSlice + 1);
    setMaxSlice(maxSlice + 1);
  };

  const handlePrevious = () => {
    setMaxSlice(maxSlice - 1);
    setMinSlice(minSlice - 1);
  };

  const openAgencyProfile = async (agency: AgencyCompetitor) => {
    // avoid popup blockers with location.assign
    // https://stackoverflow.com/questions/4663861/open-new-window-after-a-click-event-not-working-in-safari-chrome
    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari/70463940#70463940
    window.location.assign(getUrl('agency.profile', { slug: agency.slug }));
  };

  const trackAndOpenAgencyProfileOnClick = (agency: AgencyCompetitor, index: number) => {
    const trackData: TrackingParamsCpc = {
      name: 'competitorCardClicked',
      url: location.href,
      agency_external_id: agency.uuid,
      position: index,
      cta: 'agencyCompetitors',
    };

    execWithTimeout(async (resolve, _reject) => {
      await Promise.all([
        internalTrack(trackData).catch(() => {}),
        trackUntyped(
          'suggestionOpened',
          {
            referringAgency: agencySlug,
            suggestedAgency: agency.slug,
            type: 'similar',
          },
          { synchronous: true },
        ),
      ]);
      resolve();
    }).finally(() => {
      openAgencyProfile(agency);
    });
  };

  return competitors && competitors.length > 0 ? (
    <SectionBox id="similar" className={cx(className, 'py-64 bg-secondary-100')}>
      <h2 className="h6 px-gt-xs-64 px-xs-32 mb-16">
        <Trans i18nKey={'agency:profile.similar.subtitle'}>
          <b>Agencies similar</b> to {{ name: agencyName }}
        </Trans>
      </h2>
      <div className="px-gt-sm-56 px-24 relative">
        {minSlice != 0 && (
          <S.ArrowBackwardButton className="absolute">
            <Button
              fab={true}
              size="sm"
              iconSize="md"
              buttonStyle="secondary"
              buttonVariant="flat"
              icon={<ArrowBackRounded />}
              onClick={handlePrevious}
              disabled={minSlice === 0}
              id="prev-similar-work-btn"
            />
          </S.ArrowBackwardButton>
        )}
        <div className="layout-xs-column layout-row layout-wrap layout-align-start-stretch">
          {competitors.slice(minSlice, maxSlice).map((agency: AgencyCompetitor, index: number) => (
            <S.AgencyCard
              key={`${agency.slug}-${index}`}
              className="p-8 flex-100 flex-sm-50 flex-gt-sm-33 layout-column"
              onLoad={() =>
                // 'agencyImpression' events REQUIRE the parameters: agencyName,
                // agencyPlan (which should be visibility_plan), agencySlug, app, type.
                trackUntyped('agencyImpression', {
                  agencyName: agency.name,
                  agencyPlan: agency.plan,
                  agencySlug: agency.slug,
                  referringAgency: agencySlug,
                  type: 'similar',
                })
              }>
              <div className="layout-column layout-align-start-stretch flex bg-neutral-100 rounded-md shadow-2">
                <header className="layout-row px-20 pt-20">
                  <Avatar
                    src={getGumletImg(agency.logo, { preferredWidth: 48, mobile: false })}
                    shape="square"
                    imgLoading={'lazy'}
                    size="sm"
                    alt={`${agency.name} logo`}
                  />
                  <div className="layout-column px-8 layout-align-center-start text-truncate">
                    {agency.name && <span className="bold text-truncate width-100">{agency.name}</span>}
                    {agency.locality_name && (
                      <span className="small text-truncate width-100 mt-4">{agency.locality_name}</span>
                    )}
                  </div>
                </header>
                <div className="layout-column p-20 flex">
                  {`${agency.description.substring(0, 80)}...`}
                  {agency.expertises_names.length > 0 && (
                    <span className="small text-secondary-900 text-truncate mt-4">
                      {agency.expertises_names.join(', ')}
                    </span>
                  )}
                </div>
                {agency.slug && (
                  <Fragment>
                    <Divider />
                    <footer className="layout-row">
                      <div
                        onClick={() => trackAndOpenAgencyProfileOnClick(agency, index)}
                        className="m-16 flex text-left cursor-pointer"
                        role="none">
                        <span className="layout-row layout-align-space-between">
                          <span className="text-secondary-900 small">{t('agency:profile.similar.cta')}</span>
                          <ArrowForwardRounded className="text-secondary-500" style={{ fontSize: 16 }} />
                        </span>
                      </div>
                    </footer>
                  </Fragment>
                )}
              </div>
            </S.AgencyCard>
          ))}
          {maxSlice != competitors.length && (
            <Button
              size="sm"
              label={t('agency:profile.similar.seeAll')}
              iconSize="md"
              animation="none"
              className="mt-16 hide-gt-xs underline"
              buttonStyle="secondary"
              buttonVariant="default"
              onClick={handleSeeAll}
              id="see-all-similar-work-btn"
            />
          )}
        </div>

        {maxSlice != competitors.length && !isXs && (
          <S.ArrowForwardButton className="absolute">
            <Button
              fab={true}
              size="sm"
              iconSize="md"
              buttonStyle="secondary"
              buttonVariant="light"
              icon={<ArrowForwardRounded />}
              onClick={handleNext}
              disabled={maxSlice === competitors.length}
              id="next-similar-work-btn"
            />
          </S.ArrowForwardButton>
        )}
      </div>
    </SectionBox>
  ) : null;
};
