import { cx } from '@emotion/css';
import { RateReviewTwoTone } from '@mui/icons-material';
import { Divider, Rating } from '@sortlist-frontend/design-system';
import { getFormattedRating, ReviewCard } from '@sortlist-frontend/shared-components';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { isEmptyArray, keysToCamelCase } from '@sortlist-frontend/utils';
import { Fragment, useState } from 'react';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { ToggleButton } from '_components/common/ToggleButton';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useTracker } from '_core/hooks/use-tracker';
import { useGetAgencyProfile, useGetAgencyReviews } from '_core/repos/agency-profile.repo';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { SectionBox } from '_features/agency/profile/components/section-box';
import * as S from '_features/agency/profile/sections/reviews/reviews-panel.styles';

import getReportReviewUrl from './utils';

const defaultProps = {
  maxDisplayedReviews: 100,
  initialDisplayedReviews: 3,
};

export const ReviewsPanel = () => {
  const { trackUntyped } = useTracker();
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { locale, pageData, getUrl } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);
  const { data } = useGetAgencyReviews(agencySlug, locale as string);
  const { name } = { ...agency };

  const [displayedReviews, setDisplayedReviews] = useState<number>(defaultProps.initialDisplayedReviews);

  const isCollapsed = displayedReviews <= defaultProps.initialDisplayedReviews;

  return (
    <SectionBox
      id="reviews"
      title={t('agency:profile.reviews.title')}
      className={'py-gt-xs-96 py-xs-64'}
      subtitle={
        !data || isEmptyArray(data?.reviews ?? []) ? (
          <Trans i18nKey={'agency:profile.reviews.subtitleNoReview'}>No review for {{ name }} yet</Trans>
        ) : (
          <Trans i18nKey={'agency:profile.reviews.subtitle'} count={data.reviews?.length}>
            <b>{{ count: data.reviews?.length }} reviews</b>
            for {{ name }}
          </Trans>
        )
      }
      childrenHeader={
        data != null && data.global_rating != null && data.reviews.length > 0 ? (
          <div className="layout-column layout-align-xs-start-start layout-align-start-end mt-xs-32">
            <div>
              <span className="bold h2">{getFormattedRating(data.global_rating)}</span>
              <span>{'/5'}</span>
            </div>
            <div>
              <Rating rate={getFormattedRating(data.global_rating)} size={20}>
                <span className="ml-4 lh-2">{`(${t('agency:profile.reviews.count', {
                  count: data.reviews.length,
                })})`}</span>
              </Rating>
            </div>
          </div>
        ) : null
      }>
      <Fragment>
        {data && agency && data.reviews && data.reviews.length > 0 ? (
          <div className="px-gt-sm-64 px-32">
            <S.Shape className="border border-secondary-900 mb-gt-xs-32 rounded-lg shadow-2 overflow-hidden">
              {data.reviews.slice(0, displayedReviews).map((_review, index: number) => {
                const formattedReview = keysToCamelCase(_review);
                const { userFullName, slug } = formattedReview;

                return (
                  <S.ReviewContainer key={userFullName} className={cx({ 'mb-xs-16': index + 1 != displayedReviews })}>
                    <ReviewCard
                      reportUrl={getReportReviewUrl(userFullName, agency.slug, slug)}
                      review={{ ...formattedReview, agencySlug: agency.slug }}
                      agencyName={agency.name}
                    />
                    {data.reviews.length !== index + 1 && <Divider className="hide-xs" />}
                  </S.ReviewContainer>
                );
              })}
              {(data.reviews ?? []).length > 3 && (
                <Fragment>
                  {!isCollapsed && <Divider />}
                  <div className="layout-column layout-align-start-stretch">
                    <ToggleButton
                      handleToggled={() =>
                        setDisplayedReviews(
                          isCollapsed ? defaultProps.maxDisplayedReviews : defaultProps.initialDisplayedReviews,
                        )
                      }
                      seeMoreBtnLabel={
                        <Trans i18nKey={'agency:profile.reviews.seeOtherReviews'} count={data.reviews.length}>
                          See all other reviews ({{ count: data.reviews.length - 3 }})
                        </Trans>
                      }
                      seeLessBtnLabel={t('agency:profile.reviews.seeLessReviews')}
                      toggled={!isCollapsed}
                    />
                  </div>
                </Fragment>
              )}
            </S.Shape>
          </div>
        ) : null}
        <div className="px-gt-sm-64 px-32 pt-32">
          <S.ReviewCTA className="layout-column layout-gt-xs-row layout-align-center-center rounded-lg bg-secondary-100 flex">
            <div className="p-16 layout-column layout-align-center-center">
              <RateReviewTwoTone style={{ fontSize: 24 }} className="text-secondary-700" />
            </div>
            <div className="flex layout-colum py-16 px-0">
              <span className="bold mb-4">
                <Trans i18nKey={'agency:profile.reviews.postReview.title'}>Worked with {{ agencyName: name }}?</Trans>
              </span>
              <p className="small text-secondary-500">{t('agency:profile.reviews.postReview.subtitle')}</p>
            </div>
            <div className="p-16">
              <Obfuscate
                onClick={() => trackUntyped('reviewFormOpened', { name, agencySlug })}
                className="display-block btn btn-md btn-light btn-secondary"
                href={getUrl('agency.profile.review', { slug: agencySlug })}
                obfuscate={true}>
                {t('agency:profile.reviews.postReview.ctaLabel')}
              </Obfuscate>
            </div>
          </S.ReviewCTA>
        </div>
      </Fragment>
    </SectionBox>
  );
};
