import { cx } from '@emotion/css';
import { Loader, useMediaQuery } from '@sortlist-frontend/design-system';

type Props = {
  className?: string;
  message?: string;
};

export const SectionLoader: React.FC<Props> = (props) => {
  const { className, message } = props;
  const { media } = useMediaQuery();
  return (
    <div className={cx(className, 'layout-row layout-align-center-center')}>
      <Loader
        size="md"
        color="secondary-500"
        isPageLoader={media.gtXs ? false : true}
        message={message}
        messageClassName="small text-secondary-900"
        dots={true}
      />
    </div>
  );
};
