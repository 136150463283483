import { cx } from '@emotion/css';
import { ThumbUpAltTwoTone } from '@mui/icons-material';
import { ClampLines as _ClampLines, ClampLinesProps } from '@sortlist-frontend/design-system';
import type { CSSProperties, FC, ReactNode } from 'react';

import { useSharedComponentsContext } from '../../utils';
import { Review } from '../types';
import * as S from './styles';

type Props = {
  review: Review;
  agencyName?: string;
  clampLines?: number;
  className?: string;
  replyComponent?: ReactNode;
  children?: never;
};

const defaultProps = {
  clampLines: 4,
};

const iconStyle: CSSProperties = {
  fontSize: 14,
};

export const Description: FC<Props> = (props) => {
  const { review, className, clampLines, agencyName, replyComponent } = {
    ...defaultProps,
    ...props,
  };
  const { t, Trans } = useSharedComponentsContext();

  const { descriptionSummary, descriptionPros, descriptionCons, reply, recommended, userFullName } = review;

  return (
    <div
      data-testid="review-card-description"
      className={cx(
        className,
        'layout-column layout-align-start-start p-24 text-break-word sortlist-review_description_container',
      )}>
      {descriptionSummary && descriptionPros && (
        <S.Description className="mb-16">
          <b>{t('base:reviewCard.reasonForCollaboration')}</b>
          <ClampLines text={descriptionSummary} lines={clampLines} />
        </S.Description>
      )}
      {descriptionSummary && descriptionPros && (
        <S.Description className="mb-16">
          <b>{t('base:reviewCard.itemsEnjoyedDuringCollaboration')}</b>
          <ClampLines text={descriptionPros} lines={clampLines} />
        </S.Description>
      )}
      {descriptionCons && (
        <S.Description className="mb-16">
          <b>{t('base:reviewCard.areasForImprovement')}</b>
          <ClampLines text={descriptionCons} lines={clampLines} />
        </S.Description>
      )}
      {!descriptionPros && descriptionSummary && (
        <S.Description className="mb-16">
          <ClampLines text={descriptionSummary} lines={clampLines} />
        </S.Description>
      )}
      {reply && replyComponent == null && (
        <div className="pl-16 layout-row layout-align-center-center width-100">
          <div className="border-left border-secondary-300 pl-16 rounded-sm width-100">
            <span className="bold">
              {agencyName ? (
                <Trans i18nKey="base:reviewCard.agencyAnswer">{{ agencyName: agencyName }}'s answer:</Trans>
              ) : null}
            </span>
            <ClampLines text={reply} lines={clampLines} />
          </div>
        </div>
      )}
      {replyComponent != null && replyComponent}
      {recommended && userFullName && (
        <div
          data-testid="review-card-about-recommened"
          className="mt-24 py-8 rounded-sm px-16 layout-row layout-align-start-center text-info">
          <ThumbUpAltTwoTone className="mr-8" style={iconStyle}></ThumbUpAltTwoTone>
          <p className="small lh-1">
            <Trans i18nKey="base:reviewCard.recommendText">
              <span>{{ name: userFullName }}</span>
              <b>recommends this agency</b>
            </Trans>
          </p>
        </div>
      )}
    </div>
  );
};

const ClampLines = (props: Omit<ClampLinesProps, 'seeMoreText' | 'seeLessText'>) => {
  const { t } = useSharedComponentsContext();
  return (
    <_ClampLines {...props} seeLessText={t('base:clampLines.seeLess')} seeMoreText={t('base:clampLines.seeMore')} />
  );
};
